const CONSENT_STATE_VARIANTS = {
  new: 'primary',
  investigation: 'danger',
  'investigation - contacted': 'danger',
  'investigation - declined': 'danger',
  'permanently - declined': 'danger',
  'Contacted by email': 'danger',
  'Video call requested': 'danger',
  'Video call passed': 'warning',
  'Video call SUS': 'danger',
  'approved by support': 'warning',
  completed: 'success',
}

const DEFAULT_STATES_LIST = [
  'new',
  'investigation',
  'investigation - contacted',
  'investigation - declined',
  'permanently - declined',
  'Contacted by email',
  'Video call requested',
  'Video call passed',
  'Video call SUS',
  'approved by support',
]

export {
  CONSENT_STATE_VARIANTS, DEFAULT_STATES_LIST,
}
