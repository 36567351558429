<template>
  <div>
    <gql-table
      ref="contractConsentsGqlTable"
      :fields="columns"
      :query="query"
      query-type="contractConsents"
      sort-by="acceptedAt"
      :sort-desc="false"
      :actions="actions"
      :toggle-filters="true"
      @edit="onEdit"
      @restore="onRestore"
      @restoreDecision="onRestoreDecision"
    >
      <template #row-details="row">
        <row-detail :row="row" />
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.account.user.id } }">
              {{ data.item.account.user.firstName }} {{ data.item.account.user.lastName }}
              <risk-score-badge
                v-if="data.item.account.user.risk_score"
                class="mx-1"
                :score-results="data.item.account.user.risk_score_results"
                :score="data.item.account.user.risk_score"
              />
              <br>
              <small>{{ data.item.account.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.account.user.tags"
              :extras-tags="getUserExtraTags(data.item.account.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(country)="data">
        {{ data.item.account.user.country.name }}
      </template>

      <template #cell(account)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account.account_id } }">
              {{ data.item.account.account_id }} ({{ accountTypes[data.item.account.account_type_name] }} - {{ data.item.account.server.platform.id }})
            </b-link>
            <risk-score-badge
              v-if="data.item.account.risk_score"
              class="mx-1"
              :score-results="data.item.account.risk_score_results"
              :score="data.item.account.risk_score"
            />
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.account.tags"
              :extras-tags="getAccountExtraTags(data.item.account)"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(rules)="data">
        {{ data.item.account.rule_max_daily_drawdown }}%|{{ data.item.account.rule_max_total_drawdown }}%|{{ data.item.account.rule_min_trading_days }}|{{ data.item.account.rule_first_withdrawal_after }}|{{ data.item.account.rule_withdrawal_after }} - {{ ddTypes.find(rule => rule.value === data.item.account.rule_drawdown_type).label }} -

        <span
          v-for="(item, key) in data.item.account.special_options"
          :key="key"
        >
          {{ item }}
        </span>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.account.deposit, data.item.account.currency.label) }}
      </template>

      <template #cell(acceptedAt)="data">
        <b-badge
          v-if="data.item.acceptedAt"
          variant="light-success"
        >
          {{ formatDateTime(data.item.acceptedAt) }}
        </b-badge>
      </template>

      <template #cell(declinedAt)="data">
        <b-badge
          v-if="data.item.declinedAt"
          variant="light-danger"
        >
          {{ formatDateTime(data.item.declinedAt) }}
        </b-badge>
      </template>

      <template #cell(doc_expiration)="data">
        {{ formatDate(data.item.doc_expiration) }}
      </template>

      <template #cell(supportApprovedAt)="data">
        <b-badge
          v-if="data.item.supportApprovedAt"
          variant="light-info"
        >
          {{ formatDateTime(data.item.supportApprovedAt) }}
        </b-badge>
        <b-badge
          v-else-if="(data.item.files !== null || data.item.approval_required) && data.item.supportApprovedAt === null"
          variant="light-danger"
        >
          Have to approve
        </b-badge>
      </template>

      <template #cell(state)="data">
        <b-badge
          v-if="data.item.state !== 'null'"
          :variant="`light-${consentStateVariants[data.item.state]}`"
        >
          {{ data.item.state }}
        </b-badge>

        <b-dropdown
          class="mx-1"
          variant="outline-primary"
          right
          text="States"
        >
          <b-dropdown-item
            v-for="(state, key) in Object.keys(consentStateVariants)"
            :key="key"
            @click="onChangeState(data.item, state)"
          >
            {{ state }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </gql-table>
    <b-modal
      id="consentModal"
      title="Edit contract consent"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <contract-consent-form
        :consent="consentEdit"
        @submit="onEditSubmit"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BBadge, BCol, BDropdown, BDropdownItem, BLink, BModal, BRow,
} from 'bootstrap-vue'
import { dispatch } from 'vuex-pathify'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import RowDetail from '@/components/page/accounts/components/RowDetail.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { formatCurrency, formatDate, formatDateTime } from '@/plugins/formaters'
import ConsentAPI from '@/api/contract-consent'
import store from '@/store'

import { ACCOUNTS_SHORT, DEFAULT_PLATFORMS_SWITCHER, DRAWDOWN_TYPES_ENUM } from '@/enums/accounts'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { getUserExtraTags } from '@/plugins/userUtils'
import { CONSENT_STATE_VARIANTS } from '@/enums/consents'
import ContractConsentForm from '@/components/forms/contract-consent/ContractConsentForm.vue'

const query = [
  'id',
  'full_name',
  'acceptedAt',
  'declinedAt',
  'supportApprovedAt',
  'approval_required',
  'state',
  'createdAt',
  {
    account: {
      fields: [
        'id',
        'account_id',
        'account_type_name',
        'account_subtype_name',
        'deposit',
        'risk_score',
        'risk_score_results',
        'execution_type',
        'phase_move_check',
        'equity_check',
        'rule_drawdown_type',
        'rule_first_withdrawal_after',
        'rule_inheritance',
        'rule_max_daily_drawdown',
        'rule_max_days',
        'rule_max_total_drawdown',
        'rule_min_profit',
        'rule_min_trading_days',
        'rule_special',
        'rule_withdrawal_after',
        'profit_split',
        'extras_tags',
        'special_options',
        { currency: { fields: ['label'] } },
        { server: { fields: ['id', 'name', { platform: { fields: ['id'] } }] } },
        { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
        {
          user: {
            fields: [
              'firstName', 'lastName', 'id', 'email', 'risk_score_results', 'risk_score', 'kyc_at',
              { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
              { country: { fields: ['name'] } },
            ],
          },
        },
      ],
    },
  },
]

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Restore decision',
    icon: 'RefreshCwIcon',
    emit: 'restore',
  },
  {
    text: 'Restore decision and request new files',
    icon: 'FileMinusIcon',
    emit: 'restoreDecision',
  },
]

export default {
  components: {
    ContractConsentForm,
    BModal,
    BDropdownItem,
    BDropdown,
    BLink,
    GqlTable,
    BBadge,
    RowDetail,
    BCol,
    TagsList,
    RiskScoreBadge,
    BRow,
  },
  data() {
    return {
      query,
      actions,
      accountTypes: ACCOUNTS_SHORT,
      ddTypes: DRAWDOWN_TYPES_ENUM,
      consentStateVariants: CONSENT_STATE_VARIANTS,
      consentEdit: null,
      columns: [
        {
          name: 'user',
          label: 'User',
        },
        {
          name: 'country',
          label: 'Country',
        },
        {
          name: 'account',
          label: 'Account',
          filterable: {
            type: 'multientity', queryType: 'accounts', value: 'id', label: 'account_id', search: 'account_id',
          },
        },
        {
          name: 'rules',
          label: 'ID|DD|TD|FW|W',
        },
        {
          name: 'deposit',
          label: 'Deposit',
          sortable: true,
        },
        {
          name: 'acceptedAt',
          label: 'Accepted At',
          sortable: true,
        },
        {
          name: 'declinedAt',
          label: 'Declined At',
          sortable: true,
        },
        {
          name: 'supportApprovedAt',
          label: 'Support Approved At',
          sortable: true,
        },
        {
          name: 'state',
          label: 'State',
          filterable: { type: 'multienum', enum: Object.keys(CONSENT_STATE_VARIANTS) },
        },
        {
          name: 'userEmail',
          label: 'Email',
          filterable: 'like',
          visible: false,
        },
        {
          name: 'accountPlatform',
          label: 'Platform',
          filterable: {
            type: 'multienum',
            enum: DEFAULT_PLATFORMS_SWITCHER,
          },
          visible: false,
        },
        {
          name: 'isWaiting',
          label: 'Is Waiting',
          filterable: { type: 'is' },
          default: true,
          visible: false,
        },
        {
          name: 'actions',
          label: 'Actions',
        },
      ],
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    formatDate,
    getAccountExtraTags,
    getUserExtraTags,
    onEdit(item) {
      dispatch('contractConsentsList/get', item.id).then(data => {
        this.consentEdit = data
        this.$bvModal.show('consentModal')
      })
    },
    onEditSubmit() {
      this.$bvModal.hide('consentModal')
      this.$refs.contractConsentsGqlTable.reloadAll(true)
    },
    onRestoreDecision(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Consent decision will be restored and user will have to accept or decline and upload new files again. ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset!',
      }).then(result => {
        if (result.isConfirmed) {
          ConsentAPI.postRestoreDecision(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Decision was restored successfully',
                  icon: 'WarningIcon',
                  variant: 'success',
                },
              })
              this.$refs.contractConsentsGqlTable.reloadAll(true)
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Request failed',
                  icon: 'WarningIcon',
                  text: error.data.detail,
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onRestore(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Consent decision will be restored and user will have to accept or decline again. ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('contractConsentsList/update', {
            id: item.id,
            data: {
              declinedAt: null, acceptedAt: null, supportApprovedAt: null, files: null,
            },
          }).finally(() => {
            this.$refs.contractConsentsGqlTable.reloadAll(true)
          })
        }
      })
    },
    onChangeState(item, state) {
      ConsentAPI.patchConsent(item.id, { state }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'State was updated successfully',
            icon: 'WarningIcon',
            variant: 'success',
          },
        })
        this.$refs.contractConsentsGqlTable.reloadAll(true)
      })
    },
    onRemove(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Consent will be remove',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove',
      }).then(result => {
        if (result.isConfirmed) {
          ConsentAPI.removeConsent(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Consent was removed',
                icon: 'TrashIcon',
                text: '',
                variant: 'success',
              },
            })
            this.$refs.contractConsentsGqlTable.reloadAll(true)
          })
        }
      })
    },
  },
}
</script>
